@import "../../base.scss";

.genreListing {

  &__logo {
    background-image: url('../../img/full_logo.svg');
    width: s(474px);
    height: s(64px);
    position: absolute;
    top: s(-124px);
    left: s(0);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__gridMenus {
    & > * {
      width: auto;
    }
  }

  &__button {
    height: s(60px);
    width: s(128px);
    margin-top: s(41px);
    border-radius: s(30px);
    border: s(1px) solid $White;
    display: flex;
    justify-content: center;
    align-items: center;

    & > label {
      font-size: s(26px);
      max-width: s(350px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--focused {
      background-color: $White;

      & > label {
        color: $BlackTwo;
      }
    }

    body.is-touch & {
      position: fixed;
      bottom: s(-105px);
      left: 0;

      &--cancel {
        left: s(160px);
      }
    }

  }
}
