@import "../../base.scss";

@keyframes marquee {
  20% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-105%, 0);
  }
}


.menuCard {
  width: s(228px);
  height: s(304px);
  position: relative;
  margin: s(12px);

  &__thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-position: center;
    background-size: cover;

    .menuCard--liveRadio & {
      object-fit: contain;
    }
  }

  &__wrapper {
    width: s(228px);
    height: s(304px);
    background-color: $BlackTwo;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    *,
    *::before,
    *::after {
      body.is-touch & {
        pointer-events: none;
      }
    }

    body.is-touch & {
      position: relative;
      transition: box-shadow 250ms;

      &.clicked {
        box-shadow: 0 -4px 0 $White, 0 4px 0 $White;
      }
    }
  }

  &__wrapper::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: s(72px);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  &__wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: s(136px);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  &__iconWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    justify-content: center;
  }

  &__icon {
    margin-top: s(16px);
    width: s(32px);
    height: s(32px);
    z-index: 100;
  }

  &__iconLabel {
    margin-top: s(16px);
    margin-left: s(8px);
    font-size: s(17px);
    font-family: 'HelveticaNeue-Bold';
    display: flex;
    align-items: center;
  }

  &__titlesWrapper {
    position: absolute;
    bottom: s(23px);
    margin: 0 s(24px);
    width: 100%;
    height: s(44px);
    max-width: s(180px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    z-index: 100;
  }

  &__titlesInnerWrapper {
    position: relative;
  }

  &__titleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    max-width: s(180px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    text-align: center;
  }

  &__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(24px);
    color: $White;
    display: inline-block;

    &--animation {
      display: none;
    }
  }

  &__subtitle {
    position: absolute;
    top: s(95px);
    left: s(37px);
    width: s(294px);
    font-family: 'HelveticaNeue-Light';
    font-size: s(24px);
    line-height: 1.18;
    color: $White;
  }

  &__selectGenreBackground {
    position: absolute;
    right: 0;
    top: 0;
    width: s(304px);
    height: s(304px);
    background-image: url('../../img/menuCard/woman.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__selectGenreGradient {
    position: absolute;
    right: 0;
    top: 0;
    width: s(304px);
    height: s(304px);
    background-image: linear-gradient(to right, #1b1b1b, rgba(0, 0, 0, 0.6) 51%, rgba(0, 0, 0, 0));
  }

  &__progressBar {
    height: auto;
    position: absolute;
    bottom: s(90px);
    z-index: 1000;
    left: 0;

    & > div {
      width: s(400px);;
    }
  }

  &--focused {
    body:not(.is-touch) & {
      height: s(324px);
      width: s(252px);
      margin: s(0px);

      .menuCard__wrapper {
        position: absolute;
        top: s(-12px);
        left: s(-12px);
        height: s(340px);
        width: s(276px);
        border-top: s(6px) solid $White;
        border-bottom: s(6px) solid $White;
        box-shadow: 0 0 s(60px) 0 #000000;
        z-index: 2;
      }

      .menuCard__selectGenreBackground {
        width: s(327px);
        height: s(338px);
      }

      .menuCard__selectGenreGradient {
        width: s(327px);
        height: s(338px);
      }

      .menuCard__progressBar {
        & > div {
          width: s(470px);
        }
      }

      .menuCard__titlesWrapper {
        max-width: s(228px);
      }

      .menuCard__titleWrapper {
        max-width: s(228px);
      }

      .menuCard__title {
        font-size: s(28px);
      }

      .menuCard__subtitle {
        font-size: s(28px);
      }
    }
  }

  &--animate {
    body:not(.is-touch) & {
      .menuCard__titlesWrapper {
        max-width: s(203px);
      }

      .menuCard__titleWrapper {
        text-overflow: unset;
        margin: 0;
        width: auto;
        max-width: none;
        overflow: hidden;
      }

      .menuCard__title {
        animation-delay: 0.5s !important;
        display: inline-block;
      }

      .menuCard__titleWrapper--animation {

        .menuCard__title {
          margin-left: 105%;
        }
      }

      &.menuCard--focused {
        .menuCard__titlesWrapper {
          max-width: s(228px);
        }
      }
    }
  }

  &--forYou {
    .menuCard__icon {
      background-image: url('../../img/menuCard/forYou.svg');
    }
  }

  &--podcast {
    .menuCard__wrapper {
      background-size: cover;
    }

    .menuCard__icon {
      background-image: url('../../img/menuCard/podcasts.svg');
    }

    &.menuCard--focused {
      .menuCard__wrapper::after {
        body:not(.is-touch) & {
          height: s(156px);
          background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
        }
      }
    }
  }

  &--playlist {
    .menuCard__wrapper {
      background-size: cover;
    }

    .menuCard__icon {
      background-image: url('../../img/menuCard/playlist.svg');
    }
  }

  &--podcast {
    .menuCard__icon {
      background-image: url('../../img/menuCard/podcasts.svg');
    }
  }

  &--liveRadio {
    .menuCard__icon {
      background-image: url('../../img/menuCard/radio.svg');
    }
  }

  &--song {
    .menuCard__titlesWrapper {
      bottom: s(60px);
    }

    .menuCard__icon {
      background-image: url('../../img/menuCard/track.svg');
    }

    .menuCard__wrapper::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: s(136px);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }

    .menuCard__subtitle {
      top: s(250px);
      z-index: 100;
      left: 0;
      margin: 0 s(24px);
      text-align: center;
      max-width: s(180px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        .menuCard__wrapper::after {
          height: s(156px);
          background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
        }

        .menuCard__subtitle {
          width: s(228px);
          max-width: s(228px);
          top: s(290px);
        }
      }
    }
  }

  &--artistRadio {
    .menuCard__wrapper {
      background-size: cover;
    }

    .menuCard__icon {
      background-image: url('../../img/menuCard/radio.svg');
    }

    &.menuCard--focused {
      .menuCard__wrapper::after {
        body:not(.is-touch) & {
          height: s(156px);
          background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
        }
      }
    }
  }

  &--album {
    .menuCard__icon {
      background-image: url('../../img/menuCard/album.svg');
    }
  }

  &--yourLibrary {
    .menuCard__icon {
      background-image: url('../../img/menuCard/yourLibrary.svg');
    }
  }

  &--big {
    width: s(480px);
    height: s(480px);
    margin-top: s(24px);

    .menuCard__wrapper {
      width: s(480px);
      height: s(480px);
    }

    .menuCard__titlesWrapper {
      max-width: s(430px);
      bottom: s(80px);
    }

    .menuCard__titleWrapper {
      max-width: s(430px);
    }

    .menuCard__subtitle {
      max-width: s(400px);
      top: s(410px);
      width: 100%;
      text-align: center;
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        width: s(504px);

        .menuCard__wrapper {
          width: s(528px);
          height: s(528px);
        }

        .menuCard__titlesWrapper {
          max-width: s(480px);
        }

        .menuCard__titleWrapper {
          max-width: s(480px);
        }

        .menuCard__subtitle {
          max-width: s(450px);
          width: s(480px);
          top: s(455px);
        }
      }
    }
  }

  &--recentlyPlayed {
    .menuCard__iconWrapper--recentlyPlayed {
      top: s(200px);

      .menuCard__icon {
        background-image: url('../../img/menuCard/history.svg');
        background-size: contain;
        margin-top: 0;
        box-shadow: none;
      }
    }

    .menuCard__iconWrapper--recentlyPlayedPodcast {
      top: s(160px);
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        .menuCard__iconWrapper--recentlyPlayed {
          top: s(240px);
        }

        .menuCard__iconWrapper--recentlyPlayedPodcast {
          top: s(195px);
        }
      }
    }
  }

  &--genreListing {
    .menuCard__wrapper {
      background-size: cover;
      position: absolute;
    }
  }

  &--genreSelected {
    .menuCard__wrapper::before {
      content: '';
      position: absolute;
      top: s(-192px);
      left: s(99px);
      width: s(360px);
      height: s(431px);
      transform: rotate(134.9deg);
      background: rgba(0,0,0,0.76);
      background: -moz-linear-gradient(left, rgba(0,0,0,0.76) 0%, rgba(0,0,0,0.76) 12%, rgba(0,0,0,0.76) 25%, rgba(0,0,0,0.76) 39%, rgba(0,0,0,0.76) 50%, rgba(0,0,0,0.76) 51%, rgba(0,0,0,0.76) 66%, rgba(0,0,0,0.6) 84%, rgba(0,0,0,0.17) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.76)), color-stop(12%, rgba(0,0,0,0.76)), color-stop(25%, rgba(0,0,0,0.76)), color-stop(39%, rgba(0,0,0,0.76)), color-stop(50%, rgba(0,0,0,0.76)), color-stop(51%, rgba(0,0,0,0.76)), color-stop(66%, rgba(0,0,0,0.76)), color-stop(84%, rgba(0,0,0,0.6)), color-stop(100%, rgba(0,0,0,0.17)));
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.76) 0%, rgba(0,0,0,0.76) 12%, rgba(0,0,0,0.76) 25%, rgba(0,0,0,0.76) 39%, rgba(0,0,0,0.76) 50%, rgba(0,0,0,0.76) 51%, rgba(0,0,0,0.76) 66%, rgba(0,0,0,0.6) 84%, rgba(0,0,0,0.17) 100%);
      background: -o-linear-gradient(left, rgba(0,0,0,0.76) 0%, rgba(0,0,0,0.76) 12%, rgba(0,0,0,0.76) 25%, rgba(0,0,0,0.76) 39%, rgba(0,0,0,0.76) 50%, rgba(0,0,0,0.76) 51%, rgba(0,0,0,0.76) 66%, rgba(0,0,0,0.6) 84%, rgba(0,0,0,0.17) 100%);
      background: -ms-linear-gradient(left, rgba(0,0,0,0.76) 0%, rgba(0,0,0,0.76) 12%, rgba(0,0,0,0.76) 25%, rgba(0,0,0,0.76) 39%, rgba(0,0,0,0.76) 50%, rgba(0,0,0,0.76) 51%, rgba(0,0,0,0.76) 66%, rgba(0,0,0,0.6) 84%, rgba(0,0,0,0.17) 100%);
      background: linear-gradient(to right, rgba(0,0,0,0.76) 0%, rgba(0,0,0,0.76) 12%, rgba(0,0,0,0.76) 25%, rgba(0,0,0,0.76) 39%, rgba(0,0,0,0.76) 50%, rgba(0,0,0,0.76) 51%, rgba(0,0,0,0.76) 66%, rgba(0,0,0,0.6) 84%, rgba(0,0,0,0.17) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    }

    .menuCard__iconWrapper {
      justify-content: flex-end;
    }

    .menuCard__icon {
      margin-top: s(20px);
      margin-right: s(20px);
      width: s(48px);
      height: s(48px);
      background-image: url('../../img/menuCard/selected.svg');
      background-size: contain;
    }

    &.menuCard--focused {
      .menuCard__wrapper::before {
        body:not(.is-touch) & {
          transform: rotate(134.4deg);
          top: s(-207px);
          left: s(120px);
          width: s(360px);
          height: s(501px);
        }
      }
    }
  }

  &--square {
    width: s(304px);
    height: s(304px);

    .menuCard__wrapper {
      background-size: cover;
      width: s(304px);
      height: s(304px);
    }

    .menuCard__wrapper::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: s(136px);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }

    .menuCard__titlesWrapper {
      max-width: s(255px);
    }

    .menuCard__titleWrapper {
      max-width: s(255px);
    }

    .menuCard__subtitle {
      top: s(250px);
      z-index: 100;
      left: 0;
      margin: 0 s(24px);
      text-align: center;
      max-width: s(255px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        width: s(328px);

        .menuCard__wrapper {
          width: s(352px);
          height: s(346px);
        }

        .menuCard__wrapper::after {
          height: s(156px);
          background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
        }

        .menuCard__titlesWrapper {
          max-width: s(304px);
        }

        .menuCard__titleWrapper {
          max-width: s(304px);
        }

        .menuCard__subtitle {
          width: s(304px);
          max-width: s(304px);
          top: s(290px);
        }
      }
    }

    &.menuCard--animate {
      body:not(.is-touch) & {
        .menuCard__titlesWrapper {
          max-width: s(304px);
        }

        .menuCard__titleWrapper {
          max-width: 100000px;
        }
      }
    }
  }

  &--wider {
    width: s(480px);

    .menuCard__wrapper {
      width: s(480px);
    }

    .menuCard__titlesWrapper {
      max-width: s(430px);
    }

    .menuCard__titleWrapper {
      max-width: s(430px);
    }

    .menuCard__subtitle {
      top: s(250px);
      z-index: 100;
      left: 0;
      margin: 0 s(24px);
      text-align: center;
      width: s(432px);
      max-width: s(432px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        width: s(504px);
        height: s(328px);

        .menuCard__wrapper {
          width: s(528px);
        }

        .menuCard__titlesWrapper {
          max-width: s(480px);
        }

        .menuCard__titleWrapper {
          max-width: none;
        }

        .menuCard__subtitle {
          width: s(480px);
          max-width: s(480px);
          top: s(290px);
        }
      }
    }

    &.menuCard--animate {
      body:not(.is-touch) & {
        .menuCard__titlesWrapper {
          max-width: s(480px);
        }

        .menuCard__titleWrapper {
          max-width: none;
        }
      }
    }
  }

  &--viewAll {
    .menuCard__wrapper::before {
      background-image: none;
    }

    .menuCard__wrapper::after {
      background-image: none;
    }

    .menuCard__titlesWrapper {
      top: 45%;
    }

    .menuCard__subtitle {
      display: none;
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        .menuCard__wrapper::before {
          background-image: none;
        }

        .menuCard__wrapper::after {
          background-image: none;
        }
      }
    }
  }

  &--selectGenre {
    .menuCard__titlesWrapper {
      margin-left: s(37px);
      bottom: s(40px);
    }

    .menuCard__titleWrapper {
      width: auto;
    }

    .menuCard__subtitle {
      margin: 0;
      white-space: unset;
      text-align: left;
      display: block;
      top: s(80px);
      left: s(40px);
      text-overflow: unset;
      line-height: s(32px);
      width: s(262px);
      max-width: s(262px);
    }

    &.menuCard--focused {
      body:not(.is-touch) & {
        .menuCard__icon {
          margin-top: s(23px);
          margin-right: s(23px);
        }

        .menuCard__subtitle {
          margin: 0;
          white-space: unset;
          text-align: left;
          display: block;
          top: s(80px);
          left: s(40px);
          text-overflow: unset;
          line-height: s(32px);
          width: s(302px);
          max-width: s(302px);
        }
      }
    }
  }
}
