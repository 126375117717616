@import "../../base.scss";

.profileView {
  display: flex;
  width: auto;

  &__infoSection {
    display: flex;
    flex-direction: column;
    max-width: s(680px);
    min-width: s(680px);
    margin-right: s(92px);
    margin-top: s(36px);
  }

  &__background {
    position: absolute;
    top: s(-244px);
    left: s(-136px);
    filter: blur(s(30px));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: s(2000px);
    height: s(1183px);
    box-shadow: s(5px) s(3px) s(30px) black;
    z-index: -1;

    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: s(2000px);
      height: s(983px);
    }
  }

  &__infoImage {
    width: s(320px);
    height: s(320px);
    object-fit: cover;
  }

  &__infoButtonsWrapper {
    width: auto;
    display: flex;
    margin: s(32px) 0;

    &.horizontal > * {
      display: flex;
    }
  }

  &__infoSectionDescrWrapper {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: s(20px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__infoSectionDescr {
    font-family: 'HelveticaNeue-Light';
    font-size: s(28px);
    width: s(680px);
    line-height: s(38px);

    &--bold {
      font-family: 'HelveticaNeue-Bold';
    }
  }

  &__button {
    width: s(200px);

    & > label {
      display: block;
      margin-left: s(20px);
    }

    &:not(:last-child) {
      margin-right: s(26px);
    }

    &--isSaved {
      & > svg {
        transform: rotate(45deg)
      }
    }

    &--dots {
      width: s(70px);
      height: s(34px);
      margin-top: s(32px);

      & > div {
        display: flex;
        justify-content: space-between;
        width: s(43px);
      }
    }
  }
}