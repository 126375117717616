@import "../../base.scss";

.gridMenusWrapper {
  display: flex;
  width: auto;

  &--withoutPadding {
    & > div {
      padding-right: 0;

      &:last-child {
        padding-right: s(56px);
      }
    }
  }
}