@import "../../base.scss";

.profileDetails {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: s(96px);
  padding-top: s(98px);

  &__background {
    position: absolute;
    top: s(-244px);
    left: s(-136px);
    filter: blur(s(30px));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: s(2000px);
    height: s(1183px);
    box-shadow: s(5px) s(3px) s(30px) black;
    z-index: -1;
    
    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: s(2000px);
      height: s(983px);
    }
  }

  &__image {
    width: s(520px);
    height: s(520px);
    background-size: cover;
    background-position: center;
  }

  &__button {
    position: relative;
    top: 0;
    left: 0;
    width: s(200px);
    margin-bottom: s(16px);

    & > label {
      display: block;
      margin-left: s(20px);
    }

    &--isSaved {
      & > svg {
        transform: rotate(45deg)
      }
    }

    &.focused {
      box-shadow: 0 0 s(30px) 0 #000000;
    }
  }

  &__details {
    width: s(780px);
    height: s(520px);

    .scrollableTextPanel__scrollableText {
      padding-top: s(42px);
      padding-left: s(60px);
    }
  }

  &__detailsText {
    font-size: s(28px);
    line-height: 1.36;
    font-family: 'HelveticaNeue-Light';
  }
}