@import "../../base.scss";

.gridMenu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-right: s(56px);
  width: auto;
  flex-shrink: 0;

  &__label {
    margin-top: 0;
    margin-left: s(12px);
    margin-bottom: s(12px);
    font-size: s(20px);
    text-transform: uppercase;
  }

  &__row {
    display: flex;
  }

  &--placeholder {
    padding-right: 0;
  }
}
