@import "../../base.scss";

.scrollableTextPanel {
  width: s(1200px);
  height: s(650px);
  position: relative;

  .react-customscroll {
    padding-right: s(100px);
  }

  .react-customscroll-holder {
    overflow-y: hidden !important;
  }

  .react-customscroll-scrollbar-area {
    background: rgba(255, 255, 255, 0.2) !important;
    padding: 0 !important;
    width: s(4px) !important;
  }

  .react-customscroll-scrollbar-holder {
    width: s(4px) !important;
  }

  .react-customscroll-scrollbar {
    background-color: rgba(255, 255, 255, 0.2) !important;
    width: s(4px) !important;

  }

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: s(1241px);
    height: s(80px);
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  }

  &__scrollableText {
    word-wrap: break-word;

    & .container {
      font-family: 'HelveticaNeue-Light';
      padding-bottom: s(40px);

      & * {
        font-weight: normal;
      }
    }
      
    & a {
      color: $White;
      text-decoration: none;
    }
  }

  &.focused {
    .react-customscroll-scrollbar {
      background-color: white !important;
    }
  }

  &--hideScroll {
    .react-customscroll-scrollbar-area {
      display: none;
    }
  }
}