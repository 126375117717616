@import "../../base.scss";

.miniPlayer {
  height: s(200px);
  position: fixed;
  top: s(880px);
  width: s(1920px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 s(96px);
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 1%,rgba(0,0,0,0) 3%,rgba(0,0,0,0.65) 100%);

  body.is-touch & {
    z-index: 992;
  }

  &__trackInfoWrapper {
    display: flex;
    align-items: center;
    max-width: s(900px);
  }

  &__image {
    margin-right: s(40px);
    height: s(96px);
    width: s(96px);
    min-width: s(96px);
    min-height: s(96px);
  }

  &__titlesWrapper {}

  &__title {
    font-size: s(28px);
    font-family: 'HelveticaNeue-Bold';
  }

  &__desc {
    font-size: s(28px);
    font-family: 'HelveticaNeue-Light';
  }

  &__addPopup {
    position: absolute;
    bottom: s(165px);
    right: s(53px);
    width: s(326px);
    margin: 0;
    border: s(2px) solid $White;
    border-radius: s(2px);
    background-color: $Black;
  }

  &__addPopupItem {
    padding: s(20px) s(30px) s(25px) s(30px);
    font-size: s(28px);
    font-family: 'HelveticaNeue-Bold';
    height: auto;
    border: none;
    display: block;
    border-radius: 0px;
    border-bottom: s(2px) solid $White;

    &:last-child {
      border-bottom: none;

      &::after {
        content: '';
        position: absolute;
        bottom: s(-11px);
        right: s(162px);
        width: s(14px);
        height: s(14px);
        border: s(2px) solid $White;
        background-color: $Black;
        transform: rotate(45deg);
        border-left-color: $Black;
        border-top-color: $Black;
      }
    }

    &.disabled {
      background-color: $GreyTwo;
      border: none;
    }

    &.focused {
      background-color: $White;
      color: $Black;
      box-shadow: none;

      &:last-child {
        &::after {
          background-color: $White;
          border-left-color: $White;
          border-top-color: $White;
        }
      }
    }
  }

  &__controls {
    display: flex;
    position: relative;
  }

  &__progressBar {
    width: s(250px);
    right: s(205px);
  }

  &__listMenu {
    width: auto;
    margin: 0;
  }

  &__button {
    width: s(72px);
    height: s(72px);
    background-color: transparent;
    border-radius: s(120px);

    &.focused {
      box-shadow: s(0px) s(0px) s(46px) s(0px) rgba(0,0,0,0.75);
    }

    &.disabled.focused {
      background-color: white;
      border: 1px solid white;
    }

    &:not(:last-child) {
      margin-right: s(20px);
    }

    &--marginLeft {
      margin-left: s(300px);
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }
}