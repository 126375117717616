@import "../../base.scss";

.settings {
  padding-top: s(13px);
  // padding-left: s(96px);
  position: relative;
  height: s(1080px);
  box-sizing: border-box;

  &__leftMenu {
    width: s(360px);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  &__details {
    position: absolute;
    top: s(24px);
    left: s(464px);
    height: s(700px);
    width: s(1100px);
  }

  &__version {
    position: absolute;
    top: s(550px);
    left: 0;
    font-family: 'HelveticaNeue-Bold';
    font-size: s(28px);
    color: $White;
  }

  &__background {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: s(1240px);
    position: absolute;
    top: s(-185px);
    right: 0;
  }

  &__gradient {
    height: 100%;
    position: absolute;
    top: 0;

    &--left {
      left: 0;
      background-image: linear-gradient(to right, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: s(680px);
    }

    &--right {
      left: s(900px);
      background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: s(340px);
    }
  }
}