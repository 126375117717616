*,
*::before,
*::after {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

html {
  background-color: #111; }

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url(/static/media/HelveticaNeue-Regular.7fd42bbf.eot);
  /* IE9 */
  src: url(/static/media/HelveticaNeue-Regular.c740a928.woff) format("woff"), url(/static/media/HelveticaNeue-Regular.40004904.woff2) format("woff2"), url(/static/media/HelveticaNeue-Regular.0461839b.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url(/static/media/HelveticaNeue-Bold.b7f03489.eot);
  /* IE9 */
  src: url(/static/media/HelveticaNeue-Bold.fd033b07.woff) format("woff"), url(/static/media/HelveticaNeue-Bold.df903644.woff2) format("woff2"), url(/static/media/HelveticaNeue-Bold.6f979220.ttf) format("truetype"); }

.touch-gesture {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-animation: pulse 800ms both;
          animation: pulse 800ms both;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 9; }
  .dark-gesture .touch-gesture {
    background-color: rgba(0, 0, 0, 0.25); }

@-webkit-keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale(0) translate3d(-50%, -50%, 0);
            transform: scale(0) translate3d(-50%, -50%, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(2.5) translate3d(-50%, -50%, 0);
            transform: scale(2.5) translate3d(-50%, -50%, 0); } }

@keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale(0) translate3d(-50%, -50%, 0);
            transform: scale(0) translate3d(-50%, -50%, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(2.5) translate3d(-50%, -50%, 0);
            transform: scale(2.5) translate3d(-50%, -50%, 0); } }

.ListMenu {
  width: 1000000px;
  margin: 2em 0;
  /* height: 150px; */
}

.ListMenu.horizontal > * {
  display: inline-block;
}

.button {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; }
  body.is-touch .button {
    position: relative;
    overflow: hidden;
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms; }
    body.is-touch .button *,
    body.is-touch .button *::before,
    body.is-touch .button *::after {
      pointer-events: none; }
    body.is-touch .button.clicked {
      box-shadow: 0 0 18px rgba(255, 255, 255, 0.5), 0 0 6px rgba(255, 255, 255, 0.25); }
  .button.disabled {
    background-color: #C0C0C0;
    border: 1px solid #C0C0C0;
    color: #575757; }
  .button.focused {
    background-color: #FFFFFF;
    color: #1B1B1B;
    box-shadow: 0 0 27px 0 #000000; }
  .button svg + label {
    margin-left: 14px; }

.progressBar {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .progressBar__barWrapper {
    width: 214px;
    position: relative; }
  .progressBar__bar {
    width: 100%;
    height: 4px;
    opacity: 0.15;
    border-radius: 4px;
    background-color: #ffffff;
    position: absolute; }
  .progressBar__indicator {
    height: 4px;
    border-radius: 4px;
    background: #FFFFFF;
    position: absolute;
    left: 0; }
    .progressBar__indicator.focused {
      background: red; }
  .progressBar__playTimeDisplay {
    color: #FFFFFF;
    font-family: 'HelveticaNeue-Bold';
    font-size: 12px;
    margin-right: 11px;
    flex-shrink: 0; }
    .progressBar__playTimeDisplay--duration {
      margin-right: 0;
      margin-left: 11px; }

.miniPlayer {
  height: 134px;
  position: fixed;
  top: 587px;
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(0, 0, 0, 0)), color-stop(3%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.65)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0.65) 100%); }
  body.is-touch .miniPlayer {
    z-index: 992; }
  .miniPlayer__trackInfoWrapper {
    display: flex;
    align-items: center;
    max-width: 600px; }
  .miniPlayer__image {
    margin-right: 27px;
    height: 64px;
    width: 64px;
    min-width: 64px;
    min-height: 64px; }
  .miniPlayer__title {
    font-size: 19px;
    font-family: 'HelveticaNeue-Bold'; }
  .miniPlayer__desc {
    font-size: 19px;
    font-family: 'HelveticaNeue-Light'; }
  .miniPlayer__addPopup {
    position: absolute;
    bottom: 110px;
    right: 36px;
    width: 218px;
    margin: 0;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    background-color: #000000; }
  .miniPlayer__addPopupItem {
    padding: 14px 20px 17px 20px;
    font-size: 19px;
    font-family: 'HelveticaNeue-Bold';
    height: auto;
    border: none;
    display: block;
    border-radius: 0px;
    border-bottom: 2px solid #FFFFFF; }
    .miniPlayer__addPopupItem:last-child {
      border-bottom: none; }
      .miniPlayer__addPopupItem:last-child::after {
        content: '';
        position: absolute;
        bottom: -7px;
        right: 108px;
        width: 10px;
        height: 10px;
        border: 2px solid #FFFFFF;
        background-color: #000000;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        border-left-color: #000000;
        border-top-color: #000000; }
    .miniPlayer__addPopupItem.disabled {
      background-color: #C0C0C0;
      border: none; }
    .miniPlayer__addPopupItem.focused {
      background-color: #FFFFFF;
      color: #000000;
      box-shadow: none; }
      .miniPlayer__addPopupItem.focused:last-child::after {
        background-color: #FFFFFF;
        border-left-color: #FFFFFF;
        border-top-color: #FFFFFF; }
  .miniPlayer__controls {
    display: flex;
    position: relative; }
  .miniPlayer__progressBar {
    width: 167px;
    right: 137px; }
  .miniPlayer__listMenu {
    width: auto;
    margin: 0; }
  .miniPlayer__button {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border-radius: 80px; }
    .miniPlayer__button.focused {
      box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.75); }
    .miniPlayer__button.disabled.focused {
      background-color: white;
      border: 1px solid white; }
    .miniPlayer__button:not(:last-child) {
      margin-right: 14px; }
    .miniPlayer__button--marginLeft {
      margin-left: 200px; }
    .miniPlayer__button--rotate {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.audioPlayer {
  position: absolute;
  top: 0;
  left: 0; }

.menuItem {
  text-decoration: none; }
  body.is-touch .menuItem *, body.is-touch
  .menuItem *::before, body.is-touch
  .menuItem *::after {
    pointer-events: none; }
  .menuItem__wrapper {
    font-size: 20px;
    color: #848484;
    height: 32px;
    padding-top: 7px;
    display: inline-block; }
  .menuItem__switcherValue {
    font-size: 19px;
    color: #848484; }
  .menuItem--settingsItem .menuItem__wrapper {
    font-size: 19px; }
  .menuItem--switcher {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  body:not(.is-touch) .menuItem--focused .menuItem__wrapper {
    color: #FFFFFF;
    padding-top: 5px;
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    height: 30px; }
  .menuItem--focused .menuItem__switcherValue {
    color: #FFFFFF; }
  .menuItem--active .menuItem__wrapper {
    color: #FFFFFF; }
  body.is-touch .menuItem--active:not(:last-child) .menuItem__wrapper {
    padding-top: 5px;
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    height: 30px; }
  body.is-touch .menuItem__wrapper, body.is-touch .menuItem__switcherValue {
    -webkit-transition: color 200ms;
    transition: color 200ms; }
  body.is-touch .clicked .menuItem__wrapper, body.is-touch .clicked .menuItem__switcherValue {
    color: #FFFFFF; }

.backButton {
  width: 54px;
  height: 54px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .backButton__arrow {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    width: 1.667em; }
    .backButton__arrow:before, .backButton__arrow:after {
      content: "";
      position: absolute;
      background: white;
      border-radius: 0.2rem;
      display: block;
      left: -5%;
      height: 10%;
      width: 75%; }
    .backButton__arrow:before {
      top: 60%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .backButton__arrow:after {
      bottom: 60%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }

.mainMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 123px;
  width: 100%;
  display: flex;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.9)), color-stop(50%, rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0));
  padding: 38px 0 38px 64px;
  box-sizing: border-box;
  z-index: 1; }
  .mainMenu__logo {
    width: 57px;
    height: 43px;
    margin-right: 8px; }
  .mainMenu__items {
    width: 434px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-left: 30px; }
  .mainMenu__title {
    font-size: 20px;
    font-family: 'HelveticaNeue-Bold';
    margin-left: 20px; }
  .mainMenu--withoutGradient {
    background-image: none; }
  .mainMenu--backButton {
    padding: 38px 0 38px 11px; }

.exitModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .exitModal__inner {
    background: rgba(0, 0, 0, 0.9);
    height: 284px;
    width: 667px;
    z-index: 10000;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .exitModal__head {
    font-size: 34px; }
  .exitModal__subhead {
    color: #FFFFFF;
    font-family: 'HelveticaNeue-Light';
    font-size: 19px;
    margin: 31px 0; }
  .exitModal__buttonsWrapper {
    width: 467px;
    display: flex;
    justify-content: space-between;
    margin: 0; }
  .exitModal__button {
    width: 200px;
    display: flex !important;
    justify-content: center;
    align-items: center; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  90% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  90% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.toast {
  background: #FFFFFF;
  border-radius: 35px;
  height: 59px;
  width: 392px;
  position: fixed;
  top: 32px;
  left: 824px;
  z-index: 2;
  display: flex;
  align-items: center;
  -webkit-animation: fadeOut 4s linear forwards;
          animation: fadeOut 4s linear forwards; }
  .toast--libraryRadio {
    top: 144px;
    left: 291px;
    width: 407px; }
  .toast--libraryArtist {
    top: 197px;
    left: 291px;
    width: 407px; }
  .toast--libraryPlaylist {
    top: 250px;
    left: 291px;
    width: 407px; }
  .toast--libraryPodcast {
    top: 307px;
    left: 291px;
    width: 407px; }
  .toast--libraryAlbum {
    top: 357px;
    left: 291px;
    width: 407px; }
  .toast--librarySongs {
    top: 410px;
    left: 291px;
    width: 407px; }
  .toast--miniplayer {
    top: 544px;
    left: 760px; }
  .toast--fullplayer {
    top: 320px;
    left: 682px; }
  .toast__mark {
    height: 27px;
    width: 27px;
    margin: 16px 16px 16px 22px;
    flex-shrink: 0; }
  .toast__text {
    color: #000000;
    font-family: HelveticaNeue-Light;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 0;
    padding-right: 14px; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  60% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  60% {
    opacity: 1; } }

.screensaver {
  position: fixed;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  background-color: black;
  -webkit-animation: fadeIn 4s linear forwards;
          animation: fadeIn 4s linear forwards;
  overflow: hidden; }
  .screensaver *,
  .screensaver *::before,
  .screensaver *::after {
    pointer-events: none; }
  .screensaver__background {
    position: absolute;
    top: -142px;
    left: -90px;
    -webkit-filter: blur(20px);
            filter: blur(20px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1467px;
    height: 883px;
    box-shadow: 4px 2px 20px black;
    z-index: -1; }
    .screensaver__background::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: 1334px;
      height: 656px; }
  .screensaver__image {
    width: 347px;
    height: 347px;
    background-size: cover;
    background-position: center; }
  .screensaver__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 134px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box; }
  .screensaver__infoShadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.6)), color-stop(50%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
    height: 134px; }
    .screensaver__infoShadow--withUpperTitle {
      height: 170px; }
  .screensaver__trackInfoWrapper {
    max-width: 600px;
    min-width: 600px; }
    .screensaver__trackInfoWrapper--infoImage {
      display: flex; }
  .screensaver__infoImage {
    margin-right: 27px;
    height: 64px;
    width: 64px;
    min-width: 64px;
    min-height: 64px; }
  .screensaver__titlesWrapper {
    position: relative; }
  .screensaver__upperTitle {
    position: absolute;
    left: 0;
    top: -35px;
    font-family: 'HelveticaNeue-Light';
    border-bottom: 1px solid white;
    padding-bottom: 4px;
    font-size: 19px; }
  .screensaver__title {
    font-size: 19px;
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: 7px; }
  .screensaver__desc {
    font-size: 19px;
    font-family: 'HelveticaNeue-Light'; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.loadingSpinner {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .loadingSpinner__title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .loadingSpinner__spinWrapper {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .loadingSpinner__spinner {
    height: 40px;
    width: 40px;
    border: 4px solid transparent;
    border-top-color: #AF2C36;
    top: 50%;
    left: 50%;
    margin: -20px;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }
    .loadingSpinner__spinner:before {
      content: '';
      position: absolute;
      border: 2px solid transparent;
      border-radius: 50%;
      border-top-color: #AF2C36;
      top: -13px;
      left: -13px;
      right: -13px;
      bottom: -13px;
      -webkit-animation: spin 3s linear infinite;
              animation: spin 3s linear infinite; }

.hero {
  position: absolute;
  top: 96px;
  left: 0;
  display: flex;
  align-items: center; }
  .hero__image {
    width: 267px;
    height: 267px; }
  .hero__infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 167px;
    margin-left: 40px;
    width: 267px; }
  .hero__title {
    font-size: 27px;
    color: #FFFFFF; }
  .hero__message {
    font-size: 19px;
    color: #FFFFFF;
    font-family: 'HelveticaNeue-Light'; }
  .hero__button > label {
    max-width: 207px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .hero__button--focused {
    background-color: #FFFFFF; }
    .hero__button--focused > label {
      color: #1B1B1B; }

.gridMenu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-right: 38px;
  width: auto;
  flex-shrink: 0; }
  .gridMenu__label {
    margin-top: 0;
    margin-left: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    text-transform: uppercase; }
  .gridMenu__row {
    display: flex; }
  .gridMenu--placeholder {
    padding-right: 0; }

@-webkit-keyframes marquee {
  20% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(-105%, 0);
            transform: translate(-105%, 0); } }

@keyframes marquee {
  20% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(-105%, 0);
            transform: translate(-105%, 0); } }

.menuCard {
  width: 152px;
  height: 203px;
  position: relative;
  margin: 8px; }
  .menuCard__thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-position: center;
    background-size: cover; }
    .menuCard--liveRadio .menuCard__thumb {
      object-fit: contain; }
  .menuCard__wrapper {
    width: 152px;
    height: 203px;
    background-color: #1B1B1B;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden; }
    body.is-touch .menuCard__wrapper *, body.is-touch
    .menuCard__wrapper *::before, body.is-touch
    .menuCard__wrapper *::after {
      pointer-events: none; }
    body.is-touch .menuCard__wrapper {
      position: relative;
      -webkit-transition: box-shadow 250ms;
      transition: box-shadow 250ms; }
      body.is-touch .menuCard__wrapper.clicked {
        box-shadow: 0 -4px 0 #FFFFFF, 0 4px 0 #FFFFFF; }
  .menuCard__wrapper::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .menuCard__wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 91px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .menuCard__iconWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    justify-content: center; }
  .menuCard__icon {
    margin-top: 11px;
    width: 22px;
    height: 22px;
    z-index: 100; }
  .menuCard__iconLabel {
    margin-top: 11px;
    margin-left: 6px;
    font-size: 12px;
    font-family: 'HelveticaNeue-Bold';
    display: flex;
    align-items: center; }
  .menuCard__titlesWrapper {
    position: absolute;
    bottom: 16px;
    margin: 0 16px;
    width: 100%;
    height: 30px;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    z-index: 100; }
  .menuCard__titlesInnerWrapper {
    position: relative; }
  .menuCard__titleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    text-align: center; }
  .menuCard__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: 16px;
    color: #FFFFFF;
    display: inline-block; }
    .menuCard__title--animation {
      display: none; }
  .menuCard__subtitle {
    position: absolute;
    top: 64px;
    left: 25px;
    width: 196px;
    font-family: 'HelveticaNeue-Light';
    font-size: 16px;
    line-height: 1.18;
    color: #FFFFFF; }
  .menuCard__selectGenreBackground {
    position: absolute;
    right: 0;
    top: 0;
    width: 203px;
    height: 203px;
    background-image: url(/static/media/woman.49a0f0e7.png);
    background-repeat: no-repeat;
    background-size: cover; }
  .menuCard__selectGenreGradient {
    position: absolute;
    right: 0;
    top: 0;
    width: 203px;
    height: 203px;
    background-image: -webkit-gradient(linear, left top, right top, from(#1b1b1b), color-stop(51%, rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, #1b1b1b, rgba(0, 0, 0, 0.6) 51%, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, #1b1b1b, rgba(0, 0, 0, 0.6) 51%, rgba(0, 0, 0, 0)); }
  .menuCard__progressBar {
    height: auto;
    position: absolute;
    bottom: 60px;
    z-index: 1000;
    left: 0; }
    .menuCard__progressBar > div {
      width: 267px; }
  body:not(.is-touch) .menuCard--focused {
    height: 216px;
    width: 168px;
    margin: 0px; }
    body:not(.is-touch) .menuCard--focused .menuCard__wrapper {
      position: absolute;
      top: -8px;
      left: -8px;
      height: 227px;
      width: 184px;
      border-top: 4px solid #FFFFFF;
      border-bottom: 4px solid #FFFFFF;
      box-shadow: 0 0 40px 0 #000000;
      z-index: 2; }
    body:not(.is-touch) .menuCard--focused .menuCard__selectGenreBackground {
      width: 218px;
      height: 226px; }
    body:not(.is-touch) .menuCard--focused .menuCard__selectGenreGradient {
      width: 218px;
      height: 226px; }
    body:not(.is-touch) .menuCard--focused .menuCard__progressBar > div {
      width: 314px; }
    body:not(.is-touch) .menuCard--focused .menuCard__titlesWrapper {
      max-width: 152px; }
    body:not(.is-touch) .menuCard--focused .menuCard__titleWrapper {
      max-width: 152px; }
    body:not(.is-touch) .menuCard--focused .menuCard__title {
      font-size: 19px; }
    body:not(.is-touch) .menuCard--focused .menuCard__subtitle {
      font-size: 19px; }
  body:not(.is-touch) .menuCard--animate .menuCard__titlesWrapper {
    max-width: 136px; }
  body:not(.is-touch) .menuCard--animate .menuCard__titleWrapper {
    text-overflow: unset;
    margin: 0;
    width: auto;
    max-width: none;
    overflow: hidden; }
  body:not(.is-touch) .menuCard--animate .menuCard__title {
    -webkit-animation-delay: 0.5s !important;
            animation-delay: 0.5s !important;
    display: inline-block; }
  body:not(.is-touch) .menuCard--animate .menuCard__titleWrapper--animation .menuCard__title {
    margin-left: 105%; }
  body:not(.is-touch) .menuCard--animate.menuCard--focused .menuCard__titlesWrapper {
    max-width: 152px; }
  .menuCard--forYou .menuCard__icon {
    background-image: url(/static/media/forYou.96d439a9.svg); }
  .menuCard--podcast .menuCard__wrapper {
    background-size: cover; }
  .menuCard--podcast .menuCard__icon {
    background-image: url(/static/media/podcasts.21b8906b.svg); }
  body:not(.is-touch) .menuCard--podcast.menuCard--focused .menuCard__wrapper::after {
    height: 104px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(198, 0, 43, 0.8)), to(rgba(198, 0, 43, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
    background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0)); }
  .menuCard--playlist .menuCard__wrapper {
    background-size: cover; }
  .menuCard--playlist .menuCard__icon {
    background-image: url(/static/media/playlist.4e7a4f93.svg); }
  .menuCard--podcast .menuCard__icon {
    background-image: url(/static/media/podcasts.21b8906b.svg); }
  .menuCard--liveRadio .menuCard__icon {
    background-image: url(/static/media/radio.d35dda66.svg); }
  .menuCard--song .menuCard__titlesWrapper {
    bottom: 40px; }
  .menuCard--song .menuCard__icon {
    background-image: url(/static/media/track.c282e762.svg); }
  .menuCard--song .menuCard__wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 91px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .menuCard--song .menuCard__subtitle {
    top: 167px;
    z-index: 100;
    left: 0;
    margin: 0 16px;
    text-align: center;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  body:not(.is-touch) .menuCard--song.menuCard--focused .menuCard__wrapper::after {
    height: 104px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(198, 0, 43, 0.8)), to(rgba(198, 0, 43, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
    background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0)); }
  body:not(.is-touch) .menuCard--song.menuCard--focused .menuCard__subtitle {
    width: 152px;
    max-width: 152px;
    top: 194px; }
  .menuCard--artistRadio .menuCard__wrapper {
    background-size: cover; }
  .menuCard--artistRadio .menuCard__icon {
    background-image: url(/static/media/radio.d35dda66.svg); }
  body:not(.is-touch) .menuCard--artistRadio.menuCard--focused .menuCard__wrapper::after {
    height: 104px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(198, 0, 43, 0.8)), to(rgba(198, 0, 43, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
    background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0)); }
  .menuCard--album .menuCard__icon {
    background-image: url(/static/media/album.7db709fa.svg); }
  .menuCard--yourLibrary .menuCard__icon {
    background-image: url(/static/media/yourLibrary.76cb5b6d.svg); }
  .menuCard--big {
    width: 320px;
    height: 320px;
    margin-top: 16px; }
    .menuCard--big .menuCard__wrapper {
      width: 320px;
      height: 320px; }
    .menuCard--big .menuCard__titlesWrapper {
      max-width: 287px;
      bottom: 54px; }
    .menuCard--big .menuCard__titleWrapper {
      max-width: 287px; }
    .menuCard--big .menuCard__subtitle {
      max-width: 267px;
      top: 274px;
      width: 100%;
      text-align: center; }
    body:not(.is-touch) .menuCard--big.menuCard--focused {
      width: 336px; }
      body:not(.is-touch) .menuCard--big.menuCard--focused .menuCard__wrapper {
        width: 352px;
        height: 352px; }
      body:not(.is-touch) .menuCard--big.menuCard--focused .menuCard__titlesWrapper {
        max-width: 320px; }
      body:not(.is-touch) .menuCard--big.menuCard--focused .menuCard__titleWrapper {
        max-width: 320px; }
      body:not(.is-touch) .menuCard--big.menuCard--focused .menuCard__subtitle {
        max-width: 300px;
        width: 320px;
        top: 304px; }
  .menuCard--recentlyPlayed .menuCard__iconWrapper--recentlyPlayed {
    top: 134px; }
    .menuCard--recentlyPlayed .menuCard__iconWrapper--recentlyPlayed .menuCard__icon {
      background-image: url(/static/media/history.7489aafa.svg);
      background-size: contain;
      margin-top: 0;
      box-shadow: none; }
  .menuCard--recentlyPlayed .menuCard__iconWrapper--recentlyPlayedPodcast {
    top: 107px; }
  body:not(.is-touch) .menuCard--recentlyPlayed.menuCard--focused .menuCard__iconWrapper--recentlyPlayed {
    top: 160px; }
  body:not(.is-touch) .menuCard--recentlyPlayed.menuCard--focused .menuCard__iconWrapper--recentlyPlayedPodcast {
    top: 130px; }
  .menuCard--genreListing .menuCard__wrapper {
    background-size: cover;
    position: absolute; }
  .menuCard--genreSelected .menuCard__wrapper::before {
    content: '';
    position: absolute;
    top: -128px;
    left: 66px;
    width: 240px;
    height: 288px;
    -webkit-transform: rotate(134.9deg);
            transform: rotate(134.9deg);
    background: rgba(0, 0, 0, 0.76);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0.76)), color-stop(12%, rgba(0, 0, 0, 0.76)), color-stop(25%, rgba(0, 0, 0, 0.76)), color-stop(39%, rgba(0, 0, 0, 0.76)), color-stop(50%, rgba(0, 0, 0, 0.76)), color-stop(51%, rgba(0, 0, 0, 0.76)), color-stop(66%, rgba(0, 0, 0, 0.76)), color-stop(84%, rgba(0, 0, 0, 0.6)), color-stop(100%, rgba(0, 0, 0, 0.17)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.76) 12%, rgba(0, 0, 0, 0.76) 25%, rgba(0, 0, 0, 0.76) 39%, rgba(0, 0, 0, 0.76) 50%, rgba(0, 0, 0, 0.76) 51%, rgba(0, 0, 0, 0.76) 66%, rgba(0, 0, 0, 0.6) 84%, rgba(0, 0, 0, 0.17) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.76)), color-stop(12%, rgba(0, 0, 0, 0.76)), color-stop(25%, rgba(0, 0, 0, 0.76)), color-stop(39%, rgba(0, 0, 0, 0.76)), color-stop(50%, rgba(0, 0, 0, 0.76)), color-stop(51%, rgba(0, 0, 0, 0.76)), color-stop(66%, rgba(0, 0, 0, 0.76)), color-stop(84%, rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.17)));
    background: linear-gradient(to right, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.76) 12%, rgba(0, 0, 0, 0.76) 25%, rgba(0, 0, 0, 0.76) 39%, rgba(0, 0, 0, 0.76) 50%, rgba(0, 0, 0, 0.76) 51%, rgba(0, 0, 0, 0.76) 66%, rgba(0, 0, 0, 0.6) 84%, rgba(0, 0, 0, 0.17) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 ); }
  .menuCard--genreSelected .menuCard__iconWrapper {
    justify-content: flex-end; }
  .menuCard--genreSelected .menuCard__icon {
    margin-top: 14px;
    margin-right: 14px;
    width: 32px;
    height: 32px;
    background-image: url(/static/media/selected.3c091ab1.svg);
    background-size: contain; }
  body:not(.is-touch) .menuCard--genreSelected.menuCard--focused .menuCard__wrapper::before {
    -webkit-transform: rotate(134.4deg);
            transform: rotate(134.4deg);
    top: -138px;
    left: 80px;
    width: 240px;
    height: 334px; }
  .menuCard--square {
    width: 203px;
    height: 203px; }
    .menuCard--square .menuCard__wrapper {
      background-size: cover;
      width: 203px;
      height: 203px; }
    .menuCard--square .menuCard__wrapper::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 91px;
      background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
    .menuCard--square .menuCard__titlesWrapper {
      max-width: 170px; }
    .menuCard--square .menuCard__titleWrapper {
      max-width: 170px; }
    .menuCard--square .menuCard__subtitle {
      top: 167px;
      z-index: 100;
      left: 0;
      margin: 0 16px;
      text-align: center;
      max-width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    body:not(.is-touch) .menuCard--square.menuCard--focused {
      width: 219px; }
      body:not(.is-touch) .menuCard--square.menuCard--focused .menuCard__wrapper {
        width: 235px;
        height: 231px; }
      body:not(.is-touch) .menuCard--square.menuCard--focused .menuCard__wrapper::after {
        height: 104px;
        background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(198, 0, 43, 0.8)), to(rgba(198, 0, 43, 0)));
        background-image: -webkit-linear-gradient(bottom, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0));
        background-image: linear-gradient(to top, rgba(198, 0, 43, 0.8), rgba(198, 0, 43, 0)); }
      body:not(.is-touch) .menuCard--square.menuCard--focused .menuCard__titlesWrapper {
        max-width: 203px; }
      body:not(.is-touch) .menuCard--square.menuCard--focused .menuCard__titleWrapper {
        max-width: 203px; }
      body:not(.is-touch) .menuCard--square.menuCard--focused .menuCard__subtitle {
        width: 203px;
        max-width: 203px;
        top: 194px; }
    body:not(.is-touch) .menuCard--square.menuCard--animate .menuCard__titlesWrapper {
      max-width: 203px; }
    body:not(.is-touch) .menuCard--square.menuCard--animate .menuCard__titleWrapper {
      max-width: 100000px; }
  .menuCard--wider {
    width: 320px; }
    .menuCard--wider .menuCard__wrapper {
      width: 320px; }
    .menuCard--wider .menuCard__titlesWrapper {
      max-width: 287px; }
    .menuCard--wider .menuCard__titleWrapper {
      max-width: 287px; }
    .menuCard--wider .menuCard__subtitle {
      top: 167px;
      z-index: 100;
      left: 0;
      margin: 0 16px;
      text-align: center;
      width: 288px;
      max-width: 288px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    body:not(.is-touch) .menuCard--wider.menuCard--focused {
      width: 336px;
      height: 219px; }
      body:not(.is-touch) .menuCard--wider.menuCard--focused .menuCard__wrapper {
        width: 352px; }
      body:not(.is-touch) .menuCard--wider.menuCard--focused .menuCard__titlesWrapper {
        max-width: 320px; }
      body:not(.is-touch) .menuCard--wider.menuCard--focused .menuCard__titleWrapper {
        max-width: none; }
      body:not(.is-touch) .menuCard--wider.menuCard--focused .menuCard__subtitle {
        width: 320px;
        max-width: 320px;
        top: 194px; }
    body:not(.is-touch) .menuCard--wider.menuCard--animate .menuCard__titlesWrapper {
      max-width: 320px; }
    body:not(.is-touch) .menuCard--wider.menuCard--animate .menuCard__titleWrapper {
      max-width: none; }
  .menuCard--viewAll .menuCard__wrapper::before {
    background-image: none; }
  .menuCard--viewAll .menuCard__wrapper::after {
    background-image: none; }
  .menuCard--viewAll .menuCard__titlesWrapper {
    top: 45%; }
  .menuCard--viewAll .menuCard__subtitle {
    display: none; }
  body:not(.is-touch) .menuCard--viewAll.menuCard--focused .menuCard__wrapper::before {
    background-image: none; }
  body:not(.is-touch) .menuCard--viewAll.menuCard--focused .menuCard__wrapper::after {
    background-image: none; }
  .menuCard--selectGenre .menuCard__titlesWrapper {
    margin-left: 25px;
    bottom: 27px; }
  .menuCard--selectGenre .menuCard__titleWrapper {
    width: auto; }
  .menuCard--selectGenre .menuCard__subtitle {
    margin: 0;
    white-space: unset;
    text-align: left;
    display: block;
    top: 54px;
    left: 27px;
    text-overflow: unset;
    line-height: 22px;
    width: 175px;
    max-width: 175px; }
  body:not(.is-touch) .menuCard--selectGenre.menuCard--focused .menuCard__icon {
    margin-top: 16px;
    margin-right: 16px; }
  body:not(.is-touch) .menuCard--selectGenre.menuCard--focused .menuCard__subtitle {
    margin: 0;
    white-space: unset;
    text-align: left;
    display: block;
    top: 54px;
    left: 27px;
    text-overflow: unset;
    line-height: 22px;
    width: 202px;
    max-width: 202px; }

.gridMenusWrapper {
  display: flex;
  width: auto; }
  .gridMenusWrapper--withoutPadding > div {
    padding-right: 0; }
    .gridMenusWrapper--withoutPadding > div:last-child {
      padding-right: 38px; }

.home {
  width: 100%;
  height: 100%; }
  .home__gridMenus {
    position: absolute;
    top: 0;
    left: 642px; }
  .home__background {
    background-repeat: no-repeat;
    background-size: cover;
    height: 720px;
    width: 827px;
    position: absolute;
    top: -122px;
    left: -64px; }
  .home__gradient {
    height: 100%;
    position: absolute;
    top: 0; }
    .home__gradient--left {
      left: 0;
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.8)), color-stop(65%, rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7) 65%, rgba(0, 0, 0, 0));
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7) 65%, rgba(0, 0, 0, 0));
      width: 454px; }
    .home__gradient--right {
      left: 600px;
      background-image: -webkit-gradient(linear, right top, left top, from(#000000), color-stop(47%, rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(right, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: 227px; }

.genreListing__logo {
  background-image: url(/static/media/full_logo.ec7d980f.svg);
  width: 316px;
  height: 43px;
  position: absolute;
  top: -82px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain; }

.genreListing__gridMenus > * {
  width: auto; }

.genreListing__button {
  height: 40px;
  width: 86px;
  margin-top: 28px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center; }
  .genreListing__button > label {
    font-size: 18px;
    max-width: 234px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .genreListing__button--focused {
    background-color: #FFFFFF; }
    .genreListing__button--focused > label {
      color: #1B1B1B; }
  body.is-touch .genreListing__button {
    position: fixed;
    bottom: -70px;
    left: 0; }
    body.is-touch .genreListing__button--cancel {
      left: 107px; }

.viewAllPage__header {
  margin: 34px;
  width: 100%;
  font-size: 22px; }

.viewAllPage__addButton {
  margin: 0 22px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 67px; }

.viewAllPage .gridMenu {
  left: 67px; }

.viewAllPage--noData {
  width: auto;
  margin-top: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  padding-right: 64px; }

.yourLibrary {
  width: 100%;
  position: relative; }
  .yourLibrary__menu {
    position: absolute;
    top: 32px;
    width: 200px;
    margin: 0; }
  .yourLibrary__gridMenusWrapper {
    margin-left: -8px; }
    .yourLibrary__gridMenusWrapper--showMenu {
      margin-left: 265px; }
  .yourLibrary__button:not(:last-child) {
    margin-bottom: 11px; }
  .yourLibrary__addButton {
    margin: 0 22px;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border-radius: 67px; }
  .yourLibrary--noData {
    width: auto;
    margin-top: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    padding-right: 64px; }

.oneItemMenu {
  margin-right: 39px;
  flex-shrink: 0; }
  .oneItemMenu__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 8px;
    margin-left: 8px; }

.profileView {
  display: flex;
  width: auto; }
  .profileView__infoSection {
    display: flex;
    flex-direction: column;
    max-width: 454px;
    min-width: 454px;
    margin-right: 62px;
    margin-top: 24px; }
  .profileView__background {
    position: absolute;
    top: -162px;
    left: -90px;
    -webkit-filter: blur(20px);
            filter: blur(20px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1334px;
    height: 789px;
    box-shadow: 4px 2px 20px black;
    z-index: -1; }
    .profileView__background::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: 1334px;
      height: 656px; }
  .profileView__infoImage {
    width: 214px;
    height: 214px;
    object-fit: cover; }
  .profileView__infoButtonsWrapper {
    width: auto;
    display: flex;
    margin: 22px 0; }
    .profileView__infoButtonsWrapper.horizontal > * {
      display: flex; }
  .profileView__infoSectionDescrWrapper {
    display: flex;
    flex-direction: column; }
    .profileView__infoSectionDescrWrapper > * {
      margin-bottom: 14px; }
      .profileView__infoSectionDescrWrapper > *:last-child {
        margin-bottom: 0; }
  .profileView__infoSectionDescr {
    font-family: 'HelveticaNeue-Light';
    font-size: 19px;
    width: 454px;
    line-height: 26px; }
    .profileView__infoSectionDescr--bold {
      font-family: 'HelveticaNeue-Bold'; }
  .profileView__button {
    width: 134px; }
    .profileView__button > label {
      display: block;
      margin-left: 14px; }
    .profileView__button:not(:last-child) {
      margin-right: 18px; }
    .profileView__button--isSaved > svg {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .profileView__button--dots {
      width: 47px;
      height: 23px;
      margin-top: 22px; }
      .profileView__button--dots > div {
        display: flex;
        justify-content: space-between;
        width: 29px; }

.threeRowCard {
  width: 374px;
  height: 128px;
  margin: 8px;
  position: relative; }
  body.is-touch .threeRowCard *, body.is-touch
  .threeRowCard *::before, body.is-touch
  .threeRowCard *::after {
    pointer-events: none; }
  body.is-touch .threeRowCard {
    position: relative;
    -webkit-transition: box-shadow 250ms;
    transition: box-shadow 250ms;
    overflow: hidden; }
    body.is-touch .threeRowCard.clicked {
      box-shadow: 0 -4px 0 #FFFFFF, 0 4px 0 #FFFFFF; }
  .threeRowCard__wrapper {
    width: 374px;
    height: 128px;
    background-color: #1B1B1B;
    padding: 14px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .threeRowCard__titlesWrapper {
    display: flex;
    flex-direction: column; }
  .threeRowCard__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 7px; }
  .threeRowCard__subtitle {
    font-family: 'HelveticaNeue-Light';
    font-size: 16px;
    color: #FFFFFF; }
  .threeRowCard__progress {
    display: flex; }
  .threeRowCard__iconWrapper {
    display: flex;
    justify-content: center; }
  .threeRowCard__icon {
    width: 22px;
    height: 22px;
    z-index: 1;
    background-image: url(/static/media/history.7489aafa.svg);
    background-size: contain;
    margin-top: 0;
    box-shadow: none; }
  .threeRowCard__progressBar {
    position: inherit; }
    .threeRowCard__progressBar > div {
      width: 307px; }
  .threeRowCard--viewAll .threeRowCard__wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
  .threeRowCard--viewAll .threeRowCard__title {
    margin: 0; }
  body:not(.is-touch) .threeRowCard--focused {
    width: 390px;
    height: 136px;
    margin: 0px;
    margin-bottom: 8px; }
    body:not(.is-touch) .threeRowCard--focused .threeRowCard__wrapper {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 406px;
      height: 160px;
      border-top: 4px solid #FFFFFF;
      border-bottom: 4px solid #FFFFFF;
      box-shadow: 0 0 40px 0 #000000;
      z-index: 1; }
    body:not(.is-touch) .threeRowCard--focused .threeRowCard__title {
      font-size: 19px; }
    body:not(.is-touch) .threeRowCard--focused .threeRowCard__subtitle {
      font-size: 19px; }
    body:not(.is-touch) .threeRowCard--focused .threeRowCard__progressBar > div {
      width: 340px; }

.scrollableTextPanel {
  width: 800px;
  height: 434px;
  position: relative; }
  .scrollableTextPanel .react-customscroll {
    padding-right: 67px; }
  .scrollableTextPanel .react-customscroll-holder {
    overflow-y: hidden !important; }
  .scrollableTextPanel .react-customscroll-scrollbar-area {
    background: rgba(255, 255, 255, 0.2) !important;
    padding: 0 !important;
    width: 3px !important; }
  .scrollableTextPanel .react-customscroll-scrollbar-holder {
    width: 3px !important; }
  .scrollableTextPanel .react-customscroll-scrollbar {
    background-color: rgba(255, 255, 255, 0.2) !important;
    width: 3px !important; }
  .scrollableTextPanel__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 828px;
    height: 54px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#000000), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, #000000, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0)); }
  .scrollableTextPanel__scrollableText {
    word-wrap: break-word; }
    .scrollableTextPanel__scrollableText .container {
      font-family: 'HelveticaNeue-Light';
      padding-bottom: 27px; }
      .scrollableTextPanel__scrollableText .container * {
        font-weight: normal; }
    .scrollableTextPanel__scrollableText a {
      color: #FFFFFF;
      text-decoration: none; }
  .scrollableTextPanel.focused .react-customscroll-scrollbar {
    background-color: white !important; }
  .scrollableTextPanel--hideScroll .react-customscroll-scrollbar-area {
    display: none; }

.profileDetails {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: 64px;
  padding-top: 66px; }
  .profileDetails__background {
    position: absolute;
    top: -162px;
    left: -90px;
    -webkit-filter: blur(20px);
            filter: blur(20px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1334px;
    height: 789px;
    box-shadow: 4px 2px 20px black;
    z-index: -1; }
    .profileDetails__background::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: 1334px;
      height: 656px; }
  .profileDetails__image {
    width: 347px;
    height: 347px;
    background-size: cover;
    background-position: center; }
  .profileDetails__button {
    position: relative;
    top: 0;
    left: 0;
    width: 134px;
    margin-bottom: 11px; }
    .profileDetails__button > label {
      display: block;
      margin-left: 14px; }
    .profileDetails__button--isSaved > svg {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .profileDetails__button.focused {
      box-shadow: 0 0 20px 0 #000000; }
  .profileDetails__details {
    width: 520px;
    height: 347px; }
    .profileDetails__details .scrollableTextPanel__scrollableText {
      padding-top: 28px;
      padding-left: 40px; }
  .profileDetails__detailsText {
    font-size: 19px;
    line-height: 1.36;
    font-family: 'HelveticaNeue-Light'; }

.atoZMenu__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  height: 280px; }

.atoZMenu__item {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center; }
  body.is-touch .atoZMenu__item *, body.is-touch
  .atoZMenu__item *::before, body.is-touch
  .atoZMenu__item *::after {
    pointer-events: none; }
  .atoZMenu__item > label {
    color: #ffffff;
    font-family: 'HelveticaNeue-Light';
    font-size: 22px; }
  .atoZMenu__item--marginLeft {
    margin-left: 68px; }
  .atoZMenu__item.focused {
    box-shadow: 0 0 40px 0 #000000;
    background-color: #FFFFFF; }
    .atoZMenu__item.focused > label {
      color: #000000; }
    .atoZMenu__item.focused.wider {
      width: 104px; }

.search {
  width: 100%;
  height: 100%;
  display: flex; }
  .search__contentWrapper {
    margin-left: 70px;
    flex-shrink: 0;
    display: flex; }
  .search__bestMatch {
    flex-shrink: 0;
    min-width: 320px;
    min-height: 320px; }
  .search__gridMenus {
    flex-shrink: 0; }
  .search__leftWrapper {
    height: 463px;
    display: flex;
    flex-direction: column;
    width: 294px;
    flex-shrink: 0; }
    .search__leftWrapper--touchField {
      height: auto;
      justify-content: center; }
  .search__field {
    width: 248px;
    max-width: 248px;
    overflow: hidden;
    font-size: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 34px;
    padding-bottom: 10px;
    font-family: 'HelveticaNeue-Light';
    margin-left: 11px;
    min-height: 24px;
    color: #848484; }
    .search__field--active {
      border-bottom: 1px solid white;
      color: #fff; }
  .search__touchFieldWrapper {
    width: 222px;
    position: relative; }
  .search__touchField {
    width: 215px;
    max-width: 215px;
    overflow: hidden;
    font-size: 20px;
    padding-bottom: 10px;
    padding-right: 34px;
    font-family: 'HelveticaNeue-Light';
    margin-left: 11px;
    min-height: 24px;
    color: #848484;
    background-color: black;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    outline: none; }
    .search__touchField--active {
      border-bottom: 1px solid white;
      color: #fff; }
  .search__touchFieldIcon {
    position: absolute;
    top: 0;
    right: -33px; }
  .search__result {
    position: absolute;
    top: 0;
    left: 342px; }
  .search__emptyResult {
    position: absolute;
    top: 167px;
    left: 542px;
    display: flex;
    flex-direction: column;
    font-family: 'HelveticaNeue-Light'; }
    .search__emptyResult > div {
      font-size: 19px;
      text-align: center; }
      .search__emptyResult > div:first-child {
        margin-bottom: 7px;
        font-family: 'HelveticaNeue-Bold'; }

.player {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 64px; }
  .player__background {
    position: absolute;
    top: -162px;
    left: -90px;
    -webkit-filter: blur(20px);
            filter: blur(20px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1334px;
    height: 789px;
    box-shadow: 4px 2px 20px black;
    z-index: -1; }
    .player__background::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: 1334px;
      height: 656px; }
  .player__image {
    width: 347px;
    height: 347px;
    margin-top: 29px;
    background-size: cover;
    background-position: center; }
  .player__stationLogo {
    width: 74px;
    height: 74px;
    margin-bottom: 22px;
    background-size: cover;
    background-position: center; }
  .player__rightWrapper {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 309px;
    margin-left: 40px;
    margin-top: 56px; }
  .player__trackInfo {
    font-size: 19px;
    max-width: 294px; }
  .player__upperTitle {
    font-family: 'HelveticaNeue-Light';
    margin-bottom: 22px; }
  .player__title {
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: 7px; }
  .player__subtitle {
    font-family: 'HelveticaNeue-Light'; }
  .player__progressBar {
    position: static;
    position: initial;
    margin-top: 22px;
    height: auto; }
  .player__addPopup {
    position: absolute;
    bottom: 70px;
    right: -92px;
    width: 218px;
    margin: 0;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    background-color: #000000; }
  .player__addPopupItem {
    padding: 14px 20px;
    font-size: 19px;
    font-family: 'HelveticaNeue-Bold';
    height: auto;
    border: none;
    display: block;
    border-radius: 0px;
    border-bottom: 2px solid #FFFFFF; }
    .player__addPopupItem:last-child {
      border-bottom: none; }
      .player__addPopupItem:last-child::after {
        content: '';
        position: absolute;
        bottom: -7px;
        right: 108px;
        width: 10px;
        height: 10px;
        border: 2px solid #FFFFFF;
        background-color: #000000;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        border-left-color: #000000;
        border-top-color: #000000; }
    .player__addPopupItem.disabled {
      background-color: #C0C0C0;
      color: #D8D8D8;
      border: none; }
    .player__addPopupItem.focused {
      background-color: #FFFFFF;
      color: #000000;
      box-shadow: none; }
      .player__addPopupItem.focused:last-child::after {
        background-color: #FFFFFF;
        border-left-color: #FFFFFF;
        border-top-color: #FFFFFF; }
  .player__controls {
    display: flex;
    position: relative;
    margin-top: 78px; }
  .player__listMenu {
    width: auto;
    margin: 0; }
  .player__button {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border-radius: 67px; }
    .player__button.focused {
      box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.75); }
    .player__button.disabled.focused {
      background-color: white;
      border: 1px solid white; }
    .player__button:not(:last-child) {
      margin-right: 14px; }
    .player__button--rotate {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .player__bottomInfo {
    position: absolute;
    bottom: -222px;
    left: -64px;
    width: 1280px;
    height: 145px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center; }
  .player__bottomInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 50px; }
  .player__bottomInfoLabel {
    font-size: 19px;
    font-family: 'HelveticaNeue-Light'; }
  .player__bottomInfoArrow {
    width: 19px;
    height: 10px;
    background-image: url(/static/media/arrowBottom.fe58e287.svg);
    background-repeat: no-repeat;
    background-size: cover; }

.settingsAccountPanel__title {
  font-family: 'HelveticaNeue-Bold';
  font-size: 20px;
  color: #FFFFFF; }

.settingsAccountPanel__code {
  font-family: 'HelveticaNeue-Bold';
  font-size: 64px;
  color: #FFFFFF; }

.settingsAccountPanel__infoWrapper {
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .settingsAccountPanel__infoWrapper:first-child {
    border-top: none; }
  .settingsAccountPanel__infoWrapper--column {
    flex-direction: column; }
  .settingsAccountPanel__infoWrapper--withoutBorder {
    border-bottom: none; }

.settingsAccountPanel__infoDescr {
  font-family: 'HelveticaNeue-Light';
  font-size: 19px;
  color: #FFFFFF; }

.settingsAccountPanel__infoValue {
  font-family: 'HelveticaNeue-Bold';
  font-size: 19px;
  color: #FFFFFF;
  margin-left: 22px; }

.settingsAccountPanel__button {
  margin-top: 27px;
  margin-bottom: 11px;
  width: 106px; }
  .settingsAccountPanel__button--genres {
    width: 200px; }
  .settingsAccountPanel__button--requestCode {
    width: 208px;
    margin-top: 14px; }

.settingsAccountPanel__genresSection {
  margin-top: 27px; }

.settings {
  padding-top: 9px;
  position: relative;
  height: 720px;
  box-sizing: border-box; }
  .settings__leftMenu {
    width: 240px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: baseline; }
  .settings__details {
    position: absolute;
    top: 16px;
    left: 310px;
    height: 467px;
    width: 734px; }
  .settings__version {
    position: absolute;
    top: 367px;
    left: 0;
    font-family: 'HelveticaNeue-Bold';
    font-size: 19px;
    color: #FFFFFF; }
  .settings__background {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 827px;
    position: absolute;
    top: -123px;
    right: 0; }
  .settings__gradient {
    height: 100%;
    position: absolute;
    top: 0; }
    .settings__gradient--left {
      left: 0;
      background-image: -webkit-gradient(linear, left top, right top, from(#000000), color-stop(47%, rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(left, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      background-image: linear-gradient(to right, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: 454px; }
    .settings__gradient--right {
      left: 600px;
      background-image: -webkit-gradient(linear, right top, left top, from(#000000), color-stop(47%, rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(right, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: 227px; }

.mainContainer {
  width: 1280px;
  height: 720px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  font-family: "HelveticaNeue-Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300; }
  .mainContainer::-webkit-scrollbar {
    display: none; }
  .mainContainer__loading {
    background-color: black;
    min-height: 67vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white; }
    .mainContainer__loading img {
      width: 1280px;
      height: 720px; }
  .mainContainer__content {
    margin-top: 123px;
    margin-left: 64px; }
  .mainContainer__mark {
    height: 27px;
    width: 27px;
    margin: 16px 16px 16px 22px;
    flex-shrink: 0; }
  .mainContainer--geolocationError {
    display: flex;
    justify-content: center;
    align-items: center; }

