@import "../../base.scss";

.hero {
  position: absolute;
  top: s(144px);
  left: 0;
  display: flex;
  align-items: center;

  &__image {
    width: s(400px);
    height: s(400px); 
  }

  &__infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: s(250px);
    margin-left: s(60px);
    width: s(400px);
  }

  &__title {
    font-size: s(40px);
    color: $White;
  }

  &__message {
    font-size: s(28px);
    color: $White;
    font-family: 'HelveticaNeue-Light';
  }

  &__button {

    & > label {
      max-width: s(310px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--focused {
      background-color: $White;
      
      & > label {
        color: $BlackTwo;
      }
    }
  }
}
