@import "../../base.scss";

.oneItemMenu {
  margin-right: s(58px);
  flex-shrink: 0;

  &__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(20px);
    letter-spacing: s(2px);
    margin-bottom: s(12px);
    margin-left: s(12px);
  }
}
