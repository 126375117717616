@import "../../base.scss";

.threeRowCard {
  width: s(560px);
  height: s(192px);
  margin: s(12px);
  position: relative;

  *,
  *::before,
  *::after {
    body.is-touch & {
      pointer-events: none;
    }
  }

  body.is-touch & {
    position: relative;
    transition: box-shadow 250ms;
    overflow: hidden;

    &.clicked {
      box-shadow: 0 -4px 0 $White, 0 4px 0 $White;
    }
  }

  &__wrapper {
    width: s(560px);
    height: s(192px);
    background-color: $BlackTwo;
    padding: s(20px) s(18px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__titlesWrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(24px);
    color: $White;
    margin-bottom: s(10px);
  }

  &__subtitle {
    font-family: 'HelveticaNeue-Light';
    font-size: s(24px);
    color: $White;
  }

  &__progress {
    display: flex;
  }

  &__iconWrapper {
    display: flex;
    justify-content: center;
  }

  &__icon {
    width: s(32px);
    height: s(32px);
    z-index: 1;
    background-image: url('../../img/menuCard/history.svg');
    background-size: contain;
    margin-top: 0;
    box-shadow: none;
  }

  &__progressBar {
    position: inherit;

    & > div {
      width: s(460px);
    }
  }

  &--viewAll {
    .threeRowCard__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .threeRowCard__title {
      margin: 0;
    }
  }

  &--focused {
    body:not(.is-touch) & {
      width: s(584px);
      height: s(204px);
      margin: s(0px);
      margin-bottom: s(12px);

      .threeRowCard__wrapper {
        position: absolute;
        top: s(-12px);
        left: s(-12px);
        width: s(608px);
        height: s(240px);
        border-top: s(6px) solid $White;
        border-bottom: s(6px) solid $White;
        box-shadow: 0 0 s(60px) 0 #000000;
        z-index: 1;
      }

      .threeRowCard__title {
        font-size: s(28px);
      }

      .threeRowCard__subtitle {
        font-size: s(28px);
      }

      .threeRowCard__progressBar {

        & > div {
          width: s(510px);
        }
      }
    }
  }
}