@import "../../base.scss";

.exitModal {
  position: absolute;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    background: rgba(0, 0, 0, 0.9);
    height: s(426px);
    width: s(1000px);
    z-index: 10000;
    border-top: s(6px) solid white;
    border-bottom: s(6px) solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__head {
    font-size: s(50px);
  }

  &__subhead {
    color: #FFFFFF;
    font-family: 'HelveticaNeue-Light';
    font-size: s(28px);
    margin: s(46px) 0;

  }

  &__buttonsWrapper {
    width: s(700px);
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  &__button {
    width: s(300px);
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
