@import "../../base.scss";

.mainMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: s(184px);
  width: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0));
  padding: s(56px) 0 s(56px) s(96px);
  box-sizing: border-box;
  z-index: 1;

  &__logo {
    width: s(85px);
    height: s(64px);
    margin-right: s(11px);
  }

  &__items {
    width: s(650px);
    height: s(45px);
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-left: s(45px);
  }

  &__title {
    font-size: s(30px);
    font-family: 'HelveticaNeue-Bold';
    margin-left: s(30px);
  }

  &--withoutGradient {
    background-image: none;
  }

  &--backButton {
    padding: s(56px) 0 s(56px) s(16px);
  }
}