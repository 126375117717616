@import "../../base.scss";

.mainContainer {
  width: s(1920px);
  height: s(1080px);
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  top: 0;
  left: 0;

  background-color: black;
  color: white;

  font-family: "HelveticaNeue-Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;

  &::-webkit-scrollbar {
    display: none;
  }

  &__loading {
    background-color: black;
    min-height: s(100vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    img {
      width: s(1920px);
      height: s(1080px);
    }
  }

  &__content {
    margin-top: s(184px);
    margin-left: s(96px);
  }

  &__mark {
    height: s(40px);
    width: s(40px);
    margin: s(24px) s(24px) s(24px) s(32px);
    flex-shrink: 0;
  }

  &--geolocationError {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
