@import "../../base.scss";

.menuItem {
  text-decoration: none;

  *,
  *::before,
  *::after {
    body.is-touch & {
      pointer-events: none;
    }
  }

  &__wrapper {
    font-size: s(30px);
    color: $Grey;
    height: s(47px);
    padding-top: s(10px);
    display: inline-block;
  }

  &__switcherValue {
    font-size: s(28px);
    color: $Grey;
  }

  &--settingsItem {
    .menuItem__wrapper {
      font-size: s(28px);
    }
  }

  &--switcher {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--focused {
    .menuItem__wrapper {
      body:not(.is-touch) & {
        color: $White;
        padding-top: s(7px);
        border-top: s(2px) solid $White;
        border-bottom: s(2px) solid $White;
        height: s(45px);
      }
    }

    .menuItem__switcherValue {
      color: $White;
    }
  }

  &--active{
    .menuItem__wrapper {
      color: $White;
    }
  }

  &--active:not(:last-child) {
    .menuItem__wrapper {
      body.is-touch & {
        padding-top: s(7px);
        border-top: s(2px) solid $White;
        border-bottom: s(2px) solid $White;
        height: s(45px);
      }
    }
  }

  &__wrapper,
  &__switcherValue {
      body.is-touch & {
        transition: color 200ms;
      }
    .clicked & {
      body.is-touch & {
        color: $White;
      }
    }
  }
}