*,
*::before,
*::after {
  user-select: none;
}

html {
  background-color: #111;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('./fonts/HelveticaNeue-Regular.eot'); /* IE9 */
  src: url('./fonts/HelveticaNeue-Regular.woff') format('woff'),
      url('./fonts/HelveticaNeue-Regular.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Regular.ttf')  format('truetype');
}


@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('./fonts/HelveticaNeue-Bold.eot'); /* IE9 */
  src: url('./fonts/HelveticaNeue-Bold.woff') format('woff'),
      url('./fonts/HelveticaNeue-Bold.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Bold.ttf')  format('truetype');
}

.touch-gesture {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  animation: pulse 800ms both;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  backface-visibility: hidden;
  z-index: 9;

  @at-root .dark-gesture & {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(0) translate3d(-50%, -50%, 0);
  }

  to {
    opacity: 0;
    transform: scale(2.5) translate3d(-50%, -50%, 0);
  }
}