@import "../../base.scss";

.progressBar {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: s(0px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__barWrapper {
    width: s(320px);
    position: relative;
  }

  &__bar {
    width: 100%;
    height: s(6px);
    opacity: 0.15;
    border-radius: s(5px);
    background-color: #ffffff;
    position: absolute;
  }

  &__indicator {
    height: s(6px);
    border-radius: s(5px);
    background: #FFFFFF;
    position: absolute;
    left: 0;

    &.focused {
      background: red;
    }
  }

  &__playTimeDisplay {
    color: #FFFFFF;
    font-family: 'HelveticaNeue-Bold';
    font-size: s(18px);
    margin-right: s(16px);
    flex-shrink: 0;

    &--duration {
      margin-right: 0;
      margin-left: s(16px);
    }
  }
}
