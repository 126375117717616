@import "../../base.scss";

@keyframes fadeIn {
  0%  { opacity: 0; }
  20%  { opacity: 0; }
  60% { opacity: 1; }
}

.screensaver {
  position: fixed;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  background-color: black;
  animation: fadeIn 4s linear forwards;
  overflow: hidden;

  *,
  *::before,
  *::after {
    pointer-events: none;
  }

  &__background {
    position: absolute;
    top: s(-214px);
    left: s(-136px);
    filter: blur(s(30px));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: s(2200px);
    height: s(1324px);
    box-shadow: s(5px) s(3px) s(30px) black;
    z-index: -1;

    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: s(2000px);
      height: s(983px);
    }
  }

  &__image {
    width: s(520px);
    height: s(520px);
    background-size: cover;
    background-position: center;
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: s(200px);
    padding: 0 s(96px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  &__infoShadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
    height: s(200px);

    &--withUpperTitle {
      height: s(254px);
    }
  }

  &__trackInfoWrapper {
    max-width: s(900px);
    min-width: s(900px);

    &--infoImage {
      display: flex;
    }
  }

  &__infoImage {
    margin-right: s(40px);
    height: s(96px);
    width: s(96px);
    min-width: s(96px);
    min-height: s(96px);
  }

  &__titlesWrapper {
    position: relative;
  }

  &__upperTitle {
    position: absolute;
    left: 0;
    top: s(-53px);
    font-family: 'HelveticaNeue-Light';
    border-bottom: s(1px) solid white;
    padding-bottom: s(5px);
    font-size: s(28px);
  }

  &__title {
    font-size: s(28px);
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: s(10px);
  }

  &__desc {
    font-size: s(28px);
    font-family: 'HelveticaNeue-Light';
  }

  &__progress {}
}