@import "../../base.scss";

.search {
  width: 100%;
  height: 100%;
  display: flex;

  &__contentWrapper {
    margin-left: s(104px);
    flex-shrink: 0;
    display: flex;
  }

  &__bestMatch {
    flex-shrink: 0;
    min-width: s(480px);
    min-height: s(480px);
  }

  &__gridMenus {
    flex-shrink: 0;
  }

  &__leftWrapper {
    height: 463px;
    display: flex;
    flex-direction: column;
    width: s(440px);
    flex-shrink: 0;

    &--touchField {
      height: auto;
      justify-content: center;
    }
  }

  &__field {
    width: s(372px);
    max-width: s(372px);
    overflow: hidden;
    font-size: s(30px);
    border-bottom: s(1px) solid rgba(255, 255, 255, 0.3);
    margin-bottom: s(50px);
    padding-bottom: s(14px);
    font-family: 'HelveticaNeue-Light';
    margin-left: s(16px);
    min-height: s(36px);
    color: #848484;

    &--active {
      border-bottom: s(1px) solid rgb(255, 255, 255);
      color: #fff;
    }
  }

  &__touchFieldWrapper {
    width: s(332px);
    position: relative;
  }

  &__touchField {
    width: s(322px);
    max-width: s(322px);
    overflow: hidden;
    font-size: s(30px);
    padding-bottom: s(14px);
    padding-right: s(50px);
    font-family: 'HelveticaNeue-Light';
    margin-left: s(16px);
    min-height: s(36px);
    color: #848484;
    background-color: black;
    border: none;
    border-bottom: s(1px) solid rgba(255, 255, 255, 0.3);
    outline: none;

    &--active {
      border-bottom: s(1px) solid rgb(255, 255, 255);
      color: #fff;
    }
  }

  &__touchFieldIcon {
    position: absolute;
    top: 0;
    right: s(-50px);
  }

  &__result {
    position: absolute;
    top: 0;
    left: s(512px);
  }

  &__emptyResult {
    position: absolute;
    top: s(250px);
    left: s(812px);
    display: flex;
    flex-direction: column;
    font-family: 'HelveticaNeue-Light';

    & > div {
      font-size: s(28px);
      text-align: center;

      &:first-child {
        margin-bottom: s(10px);
        font-family: 'HelveticaNeue-Bold';
      }
    }
  }
}
