@import "../../base.scss";

.backButton {
  width: s(80px);
  height: s(80px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__arrow {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    width: 1.667em;

    &:before,
    &:after {
      content:"";
      position: absolute;
      background: white;
      border-radius: 0.2rem;
      display: block;
      left: -5%;
      height: 10%;
      width: 75%;
    }

    &:before {
      top: 60%;
      transform: rotate(45deg);
    }
    &:after {
      bottom: 60%;
      transform: rotate(-45deg);
    }
  }
}