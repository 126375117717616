// base.scss - Only shared variables and functions should be stored here. Common CSS rules must go in index.scss

// SCREENSIZE.scss is imported from either src/720p or src/1080p depending on the SASS_PATHS variable in .env
@import "./SCREENSIZE.scss";

@function s($input,$offset:0) {
  @return ceil($input / $screen-size-factor);
}

$IHR-GRAY: #909295;

$BrandRed: #C6002B;
$Grey: #848484;
$GreyTwo: #C0C0C0;
$GreyThree: #484848;
$Black: #000000;
$BlackTwo: #1B1B1B;
$White: #FFFFFF;
