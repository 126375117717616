@import "../../base.scss";

.player {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: s(96px);

  &__background {
    position: absolute;
    top: s(-244px);
    left: s(-136px);
    filter: blur(s(30px));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: s(2000px);
    height: s(1183px);
    box-shadow: s(5px) s(3px) s(30px) black;
    z-index: -1;
    
    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      width: s(2000px);
      height: s(983px);
    }
  }

  &__image {
    width: s(520px);
    height: s(520px);
    margin-top: s(43px);
    background-size: cover;
    background-position: center;
  }

  &__stationLogo {
    width: s(110px);
    height: s(110px);
    margin-bottom: s(32px);
    background-size: cover;
    background-position: center;
  }

  &__rightWrapper {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: s(463px);
    margin-left: s(60px);
    margin-top: s(83px);
  }

  &__trackInfo {
    font-size: s(28px);
    max-width: s(440px);
  }

  &__upperTitle {
    font-family: 'HelveticaNeue-Light';
    margin-bottom: s(32px);
  }

  &__title {
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: s(10px);
  }

  &__subtitle {
    font-family: 'HelveticaNeue-Light';
  }

  &__progressBar {
    position: initial;
    margin-top: s(32px);
    height: auto;
  }

  &__addPopup {
    position: absolute;
    bottom: s(105px);
    right: s(-138px);
    width: s(326px);
    margin: 0;
    border: s(2px) solid $White;
    border-radius: s(2px);
    background-color: $Black;
  }

  &__addPopupItem {
    padding: s(20px) s(30px);
    font-size: s(28px);
    font-family: 'HelveticaNeue-Bold';
    height: auto;
    border: none;
    display: block;
    border-radius: 0px;
    border-bottom: s(2px) solid $White;

    &:last-child {
      border-bottom: none;

      &::after {
        content: '';
        position: absolute;
        bottom: s(-11px);
        right: s(162px);
        width: s(14px);
        height: s(14px);
        border: s(2px) solid $White;
        background-color: $Black;
        transform: rotate(45deg);
        border-left-color: $Black;
        border-top-color: $Black;
      }
    }

    &.disabled {
      background-color: $GreyTwo;
      color: #D8D8D8;
      border: none;
    }

    &.focused {
      background-color: $White;
      color: $Black;
      box-shadow: none;

      &:last-child {
        &::after {
          background-color: $White;
          border-left-color: $White;
          border-top-color: $White;
        }
      }
    }
  }

  &__controls {
    display: flex;
    position: relative;
    margin-top: s(117px);
  }

  &__listMenu {
    width: auto;
    margin: 0;
  }

  &__button {
    width: s(72px);
    height: s(72px);
    background-color: transparent;
    border-radius: s(100px);

    &.focused {
      box-shadow: s(0px) s(0px) s(46px) s(0px) rgba(0,0,0,0.75);
    }

    &.disabled.focused {
      background-color: white;
      border: s(1px) solid white;
    }

    &:not(:last-child) {
      margin-right: s(20px);
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__bottomInfo {
    position: absolute;
    bottom: s(-333px);
    left: s(-96px);
    width: s(1920px);
    height: s(217px);
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottomInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: s(75px);
  }

  &__bottomInfoLabel {
    font-size: s(28px);
    font-family: 'HelveticaNeue-Light';
  }

  &__bottomInfoArrow {
    width: s(28px);
    height: s(14px);
    background-image: url('../../img/player/arrowBottom.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
