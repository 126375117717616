@import "../../base.scss";

.yourLibrary {
  width: 100%;
  position: relative;

  &__menu {
    position: absolute;
    top: s(48px);
    width: s(300px);
    margin: 0;
  }

  &__gridMenusWrapper {
    margin-left: s(-12px);    

    &--showMenu {
      margin-left: s(397px);
    }
  }

  &__button {

    &:not(:last-child) {
      margin-bottom: s(16px);
    }
  }

  &__addButton {
    margin: 0 s(32px);
    width: s(72px);
    height: s(72px);
    background-color: transparent;
    border-radius: s(100px);
  }

  &--noData {
    width: auto;
    margin-top: s(480px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: s(32px);
    padding-right: s(96px);
  }
}