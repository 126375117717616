@import "../../base.scss";

.atoZMenu {

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    height: s(420px);
  }

  &__item {
    width: s(68px);
    height: s(68px);
    display: flex;
    justify-content: center;
    align-items: center;

    *,
    *::before,
    *::after {
      body.is-touch & {
        pointer-events: none;
      }
    }

    & > label {
      color: #ffffff;
      font-family: 'HelveticaNeue-Light';
      font-size: s(32px);
    }

    &--marginLeft {
      margin-left: s(102px);
    }

    &.focused {
      box-shadow: 0 0 s(60px) 0 #000000;
      background-color: $White;

      & > label {
        color: #000000;
      }

      &.wider {
        width: s(156px);
      }
    }
  }
}