@import "../../base.scss";

@keyframes fadeOut {
  0%  { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  90% { opacity: 0; }
  100% { opacity: 0; }
}

.toast {
  background: #FFFFFF;
  border-radius: s(52px);
  height: s(88px);
  width: s(588px);
  position: fixed;
  top: s(48px);
  left: s(1236px);
  z-index: 2;
  display: flex;
  align-items: center;
  animation: fadeOut 4s linear forwards;

  &--libraryRadio {
    top: s(215px);
    left: s(436px);
    width: s(610px);
  }

  &--libraryArtist {
    top: s(295px);
    left: s(436px);
    width: s(610px);
  }

  &--libraryPlaylist {
    top: s(375px);
    left: s(436px);
    width: s(610px);
  }

  &--libraryPodcast {
    top: s(460px);
    left: s(436px);
    width: s(610px);
  }

  &--libraryAlbum {
    top: s(535px);
    left: s(436px);
    width: s(610px);
  }

  &--librarySongs {
    top: s(615px);
    left: s(436px);
    width: s(610px);
  }

  &--miniplayer {
    top: s(816px);
    left: s(1140px);
  }

  &--fullplayer {
    top: s(480px);
    left: s(1022px);
  }

  &__mark {
    height: s(40px);
    width: s(40px);
    margin: s(24px) s(24px) s(24px) s(32px);
    flex-shrink: 0;
  }

  &__text {
    color: #000000;
    font-family: HelveticaNeue-Light;
    font-size: s(22px);
    line-height: s(27px);
    padding: s(14px) 0;
    padding-right: s(20px);
  }
}
