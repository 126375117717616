@import "../../base.scss";

.button {
  height: s(60px);
  border-radius: s(30px);
  border: s(1px) solid $White;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: s(26px);

  body.is-touch & {
    position: relative;
    overflow: hidden;
    transition-duration: 250ms;

    *,
    *::before,
    *::after {
      pointer-events: none;
    }

    &.clicked {
      box-shadow: 0 0 18px rgba(255, 255, 255, 0.5), 0 0 6px rgba(255, 255, 255, 0.25);
    }
  }

  &.disabled {
    background-color: $GreyTwo;
    border: s(1px) solid $GreyTwo;
    color: #575757;
  }

  &.focused {
    background-color: $White;
    color: $BlackTwo;
    box-shadow: 0 0 s(40px) 0 #000000;
  }

  svg + label {
    margin-left: 14px;
  }
}