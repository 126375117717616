@import "../../base.scss";

.settingsAccountPanel {

  &__mainSection {}

  &__title {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(30px);
    color: $White;
  }

  &__code {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(96px);
    color: $White;
  }

  &__infoWrapper {
    display: flex;
    padding: s(24px) 0;
    border-bottom: s(1px) solid rgba(255, 255, 255, 0.3);

    &:first-child {
      border-top: none;
    }

    &--column {
      flex-direction: column;
    }

    &--withoutBorder {
      border-bottom: none;
    }
  }

  &__infoDescr {
    font-family: 'HelveticaNeue-Light';
    font-size: s(28px);
    color: $White;
  }

  &__infoValue {
    font-family: 'HelveticaNeue-Bold';
    font-size: s(28px);
    color: $White;
    margin-left: s(32px);
  }

  &__button {
    margin-top: s(40px);
    margin-bottom: s(16px);
    width: s(158px);

    &--genres {
      width: s(300px);
    }

    &--requestCode {
      width: s(312px);
      margin-top: s(20px);
    }
  }

  &__genresSection {
    margin-top: s(40px);
  }
}