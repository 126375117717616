@import "../../base.scss";

.viewAllPage {

  &__header {
    margin: s(50px);
    width: 100%;
    font-size: s(32px);
  }

  &__addButton {
    margin: 0 s(32px);
    width: s(72px);
    height: s(72px);
    background-color: transparent;
    border-radius: s(100px);
  }

  .gridMenu {
    left: s(100px);
  }

  &--noData {
    width: auto;
    margin-top: s(480px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: s(32px);
    padding-right: s(96px);
  }

}

