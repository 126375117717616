@import "../../base.scss";

@keyframes spin{
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.loadingSpinner {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: s(1920px);
  height: s(1080px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: s(24px);
    font-weight: 500;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: s(30px);
  }

  &__spinWrapper {
    width: s(120px);
    height: s(120px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__spinner {
    height: s(60px);
    width: s(60px);
    border: s(6px) solid transparent;
    border-top-color: #AF2C36;
    top: 50%;
    left: 50%;
    margin: s(-30px);
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &:before{
      content:'';
      position: absolute;
      border: s(3px) solid transparent;
      border-radius: 50%;
      border-top-color: #AF2C36;
      top: s(-20px);
      left: s(-20px);
      right: s(-20px);
      bottom: s(-20px);
      animation: spin 3s linear infinite;
    }
  }
}