@import "../../base.scss";

.home {
  width: 100%;
  height: 100%;

  &__gridMenus {
    position: absolute;
    top: 0;
    left: s(962px);
  }

  &__background {
    background-repeat: no-repeat;
    background-size: cover;
    height: s(1080px);
    width: s(1240px);
    position: absolute;
    top: s(-184px);
    left: s(-96px);
  }

  &__gradient {
    height: 100%;
    position: absolute;
    top: 0;

    &--left {
      left: 0;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7) 65%, rgba(0, 0, 0, 0));
      width: s(680px);
    }

    &--right {
      left: s(900px);
      background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0));
      width: s(340px);
    }
  }
}